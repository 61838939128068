import { useMutation, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import {
  MeetingNote,
  NotesHeader,
  MeetingLogIngestionStats,
  BulkMeetingLogIngestionStats,
} from "./models";
import { householdKeys } from "@/feature/household/queries";
import { ErrorResponse } from "@/lib/api/shared/errors";
import { Routes } from "feature/routes";
import { useAppContext } from "providers/AppContextProvider";
import useNotification from "providers/NotificationProvider/useNotification";

export const meetingNotesKeys = {
  main: (householdId: string) => [householdId, "meetingNotes"] as const,
  single: (householdId: string, meetingNoteId: string) =>
    [...meetingNotesKeys.main(householdId), meetingNoteId] as const,
  headers: (householdId: string) => [...meetingNotesKeys.main(householdId), "headers"] as const,
  search: (householdId: string, searchQuery: string) =>
    [...meetingNotesKeys.main(householdId), "search", searchQuery] as const,
  emailResponse: (householdId: string, meetingNoteId: string) =>
    [...meetingNotesKeys.main(householdId), "emailResponse", meetingNoteId] as const,
};

export const useMeetingNoteHeadersQuery = (householdId: string): UseQueryResult<NotesHeader[]> => {
  const routeConfig = Routes.API.MeetingNotes.ListHeaders;
  const { featureFlags, selectedTeams } = useAppContext();

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(householdId),
    scope: routeConfig.scope,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
    selectedTeams,
  });

  return useQuery({
    queryKey: meetingNotesKeys.headers(householdId),
    queryFn: () => {
      return axios.get(url).then((response) => response.data);
    },
    staleTime: 1000 * 60 * 60 * 12,
    retry: 1,
  });
};

export const useMeetingNoteQuery = (
  householdId: string,
  meetingNoteId: string,
  enabled: boolean = true
): UseQueryResult<MeetingNote> => {
  const routeConfig = Routes.API.MeetingNotes.Id;
  const { featureFlags, selectedTeams } = useAppContext();

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(householdId, meetingNoteId),
    scope: routeConfig.scope,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
    selectedTeams,
  });

  return useQuery({
    queryKey: meetingNotesKeys.single(householdId, meetingNoteId),
    queryFn: () => {
      return axios.get(url).then((response) => response.data);
    },
    staleTime: 1000 * 60 * 60 * 12,
    retry: 1,
    enabled,
  });
};

export const useSearchMeetingNotesQuery = (
  householdId: string,
  searchQuery: string
): UseQueryResult<MeetingNote[]> => {
  const routeConfig = Routes.API.MeetingNotes.Search;
  const { featureFlags, selectedTeams } = useAppContext();

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(householdId),
    scope: routeConfig.scope,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
    selectedTeams,
  });

  return useQuery({
    queryKey: meetingNotesKeys.search(householdId, searchQuery),
    queryFn: () => {
      return axios.get(url, { params: { searchQuery } }).then((response) => response.data);
    },
    staleTime: 1000 * 60 * 60 * 12,
    retry: 1,
    enabled: false,
  });
};

export const useEmailResponseQuery = (
  householdId: string,
  meetingNoteId: string
): UseQueryResult<{ emailResponse: string }> => {
  const routeConfig = Routes.API.MeetingNotes.EmailResponse;
  const { featureFlags, selectedTeams } = useAppContext();

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(meetingNoteId),
    scope: routeConfig.scope,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
    selectedTeams,
  });

  return useQuery({
    queryKey: meetingNotesKeys.emailResponse(householdId, meetingNoteId),
    queryFn: () => {
      return axios.get(url).then((response) => response.data);
    },
    staleTime: 1000 * 60 * 60 * 12,
    retry: 1,
  });
};

export const useGenerateEmailResponseMutation = (householdId: string, meetingNoteId: string) => {
  const queryClient = useQueryClient();
  const { featureFlags, selectedTeams } = useAppContext();
  const { showErrorNotification } = useNotification();
  const routeConfig = Routes.API.MeetingNotes.GenerateEmailResponse;

  return useMutation({
    mutationFn: (props: { meetingNoteId: string }) => {
      const url = Routes.Utils.apiRouteWithTeam({
        url: routeConfig.getUrl(meetingNoteId),
        scope: routeConfig.scope,
        featureFlagEnabled: featureFlags["teams-access-control"] === true,
        selectedTeams,
      });

      return axios.post<{ emailResponse: string }>(url, {
        meetingNoteId: props.meetingNoteId,
      });
    },

    onSuccess: ({ data }) => {
      return queryClient.setQueryData(
        meetingNotesKeys.emailResponse(householdId, meetingNoteId),
        data
      );
    },

    onError: (error: AxiosError<any>) => {
      if (error.response?.data?.message) {
        showErrorNotification(error.response.data.message);
      }
    },
  });
};

export const useImportTranscriptForNoteMutation = (householdId: string, noteId: string) => {
  const queryClient = useQueryClient();
  const routeConfig = Routes.API.MeetingNotes.ImportTranscript;
  const { featureFlags, selectedTeams } = useAppContext();

  return useMutation({
    mutationFn: (props: { transcriptId: string }) => {
      const url = Routes.Utils.apiRouteWithTeam({
        url: routeConfig.getUrl(householdId, noteId, props.transcriptId),
        scope: routeConfig.scope,
        featureFlagEnabled: featureFlags["teams-access-control"] === true,
        selectedTeams,
      });
      return axios.request<MeetingLogIngestionStats | ErrorResponse>({
        url: `${url}?dryRun=false`,
        method: "POST",
      });
    },

    onSettled: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: meetingNotesKeys.headers(householdId) }),
        queryClient.invalidateQueries({ queryKey: meetingNotesKeys.main(householdId) }),
      ]);
    },
  });
};

export const useRefreshHouseholdMeetingLogs = (householdId: string) => {
  const queryClient = useQueryClient();
  const routeConfig = Routes.API.MeetingNotes.RefreshMeetingLogs;
  const { featureFlags, selectedTeams } = useAppContext();

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(householdId),
    scope: routeConfig.scope,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
    selectedTeams,
  });
  return useMutation({
    mutationFn: () => {
      return axios.request<BulkMeetingLogIngestionStats | ErrorResponse>({
        url: `${url}?dryRun=false`,
        method: "POST",
      });
    },

    onSettled: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: meetingNotesKeys.headers(householdId) }),
        queryClient.invalidateQueries({ queryKey: meetingNotesKeys.main(householdId) }),
      ]);
    },
  });
};

export const useNoteDeleteQuery = (householdId: string, noteId: string) => {
  const queryClient = useQueryClient();
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.Notes.Delete;

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(householdId, noteId),
    scope: routeConfig.scope,
    selectedTeams,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
    scopeIdOverride: householdId,
  });

  return useMutation({
    mutationFn: () => {
      const res = axios.request<{} | ErrorResponse>({
        url,
        method: routeConfig.method,
      });
      return res;
    },
    onSettled: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: householdKeys.notes(householdId) }),
        queryClient.invalidateQueries({ queryKey: householdKeys.detail(householdId) }),
        queryClient.invalidateQueries({ queryKey: householdKeys.all }),
        queryClient.invalidateQueries({ queryKey: meetingNotesKeys.headers(householdId) }),
      ]);
    },
  });
};
