import { HouseholdCore } from "feature/household/models";
import { Plan } from "feature/subscription/models";
import { TeamRole } from "feature/team-roles/models";
import { EmployeeCore } from "feature/user/models";
import { PaginatedResponse } from "lib/api";

export const ALL_MY_TEAMS = "all" as const;

interface AllMyTeams {
  readonly type: typeof ALL_MY_TEAMS;
}
interface SpecificTeam {
  readonly type: "individual";
  readonly team: TeamCore;
}

export type SelectedTeams = AllMyTeams | SpecificTeam;

export type TeamCore = {
  readonly id: string;
  readonly name: string;
  readonly description: string;
  readonly templateTeam: boolean;
};

export interface TeamMember {
  readonly id: string;
  readonly displayTitle: string;
  readonly employee: EmployeeCore;
  readonly isPrimary: boolean;
  readonly roles: TeamRole[];
  readonly teamAccessType: "direct" | "applied";
  readonly teamId: string;
  readonly teamName: string;
}

export type Team = TeamCore & {
  readonly members: TeamMember[];
  readonly accountGroups: HouseholdCore[];
  readonly plan: Plan;
  readonly appliedTeam: TeamCore | undefined;
};

export interface GetTeamsResponse extends PaginatedResponse<TeamCore> {}

export interface MigratableHousehold {
  readonly id: string;
  readonly name: string;
  readonly accounts: number;
  readonly clients: number;
  readonly advisorId?: string;
  readonly advisorName?: string;
  readonly archived: boolean;
}

export interface GetMigratableHouseholds {
  readonly households: MigratableHousehold[];
}

export type TeamDetailsView = "team" | "member";
