export const APP_ENV_LOCAL = "local";
export const APP_ENV_STAGING = "staging";
export const APP_ENV_DEMO = "demo";
export const APP_ENV_PROD = "prod";
export const APP_ENV_BUILD = "build";

export type APP_ENV_VALUES =
  | typeof APP_ENV_LOCAL
  | typeof APP_ENV_STAGING
  | typeof APP_ENV_DEMO
  | typeof APP_ENV_PROD
  | typeof APP_ENV_BUILD;

export const isDev = (): boolean => process.env.APP_ENV === APP_ENV_LOCAL;
export const isStaging = (): boolean => process.env.APP_ENV === APP_ENV_STAGING;
export const isDemo = (): boolean => process.env.APP_ENV === APP_ENV_DEMO;
export const isProd = (): boolean => process.env.APP_ENV === APP_ENV_PROD;

export const isNonProd = (): boolean =>
  process.env.APP_ENV === APP_ENV_LOCAL ||
  process.env.APP_ENV === APP_ENV_STAGING ||
  process.env.APP_ENV === APP_ENV_DEMO;

// Sometimes we want our local instances to look like staging. Ex: When interacting with AWS services.
export const getAppStage = (forceLocalToStaging?: boolean): APP_ENV_VALUES => {
  if (isDev()) {
    return forceLocalToStaging ? APP_ENV_STAGING : APP_ENV_LOCAL;
  }
  if (isStaging()) {
    return APP_ENV_STAGING;
  }
  if (isDemo()) {
    return APP_ENV_DEMO;
  }
  if (isProd()) {
    return APP_ENV_PROD;
  }
  if (process.env.NEXT_PHASE === "phase-production-build") {
    return APP_ENV_BUILD;
  }
  throw new Error("APP_ENV is not set or is set to an unexpected value");
};

export const getClientSafeAppStage = (): APP_ENV_VALUES => {
  if (typeof window === "undefined") {
    return getAppStage();
  }

  const hostname = window.location.hostname;

  if (hostname.includes("staging")) {
    return APP_ENV_STAGING;
  }

  if (hostname.includes("demo")) {
    return APP_ENV_DEMO;
  }

  if (hostname.includes("advisor")) {
    return APP_ENV_PROD;
  }

  return APP_ENV_LOCAL;
};

export const getClientSafeDatadogEnv = (): string => {
  const stage = getClientSafeAppStage();

  return stage === "prod" ? "production" : stage;
};
