import { Portal, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Fragment, useEffect } from "react";
import useNotification from "../useNotification";

const SECONDS_TO_SHOW_NOTIFICATION_FOR = 6;

const Notification = () => {
  const {
    notificationText,
    notificationBullets,
    notificationIsShown,
    hideNotification,
    notificationAutoDismisses,
    notificationType,
  } = useNotification();

  useEffect(() => {
    if (notificationAutoDismisses) {
      const timeout = setTimeout(() => {
        hideNotification();
      }, SECONDS_TO_SHOW_NOTIFICATION_FOR * 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [hideNotification, notificationAutoDismisses]);

  return (
    <Transition
      show={notificationIsShown}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:-translate-y-10"
      enterTo="translate-y-0 opacity-100"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Portal>
        <div
          aria-live="assertive"
          className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-8"
        >
          <div className="flex w-full flex-col items-center space-y-4">
            <div
              className="pointer-events-auto w-fit max-w-lg overflow-hidden rounded-lg border border-gray-200 bg-gray-50 shadow-xl"
              data-notification={notificationType}
            >
              <div className="p-4">
                <div className="flex items-center">
                  {notificationType === "error" && (
                    <XCircleIcon className="mr-2 h-7 w-7 text-error-red" />
                  )}
                  {notificationType === "success" && (
                    <CheckCircleIcon className="mr-2 h-7 w-7 text-accent-blue" />
                  )}
                  {notificationType === "warning" && (
                    <ExclamationTriangleIcon className="text-accent-yellow mr-2 h-7 w-7" />
                  )}
                  <div className="flex flex-1 justify-between">
                    <p className="flex-1 text-sm font-medium text-alternativ-monochrome-dark">
                      {notificationText}
                      {notificationBullets && notificationBullets.length > 0 && (
                        <ul className="ml-4 list-disc">
                          {notificationBullets.map((bullet, index) => (
                            <li key={index}>{bullet}</li>
                          ))}
                        </ul>
                      )}
                    </p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md  text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent-blue focus:ring-offset-2"
                      onClick={() => hideNotification()}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Portal>
    </Transition>
  );
};

export default Notification;
