import { API } from "./api";
import { AppScope } from "./models";
import { PAGE } from "./page";
import { ALL_MY_TEAMS, SelectedTeams } from "../teams/models";

interface Props {
  readonly url: string;
  readonly scope: AppScope;
  readonly featureFlagEnabled: boolean;
  readonly selectedTeams: SelectedTeams; // This is essentially the browser value for what team a user is working in. Often it's grabbed via the AppContext
  readonly scopeIdOverride?: string; // When creating new entities, if a user is in the `ALL` teams view, we need to be able to override the value to specify which team to associate the entity with.
}

const pageRouteWithTeam = (props: Props) => {
  const { url, scope, featureFlagEnabled, selectedTeams, scopeIdOverride } = props;
  if (featureFlagEnabled) {
    switch (scope) {
      case "global": {
        return `/global/${url}`;
      }
      case "team": {
        if (scopeIdOverride) {
          return `/team/${scopeIdOverride}/${url}`;
        }
        const type = selectedTeams.type;
        switch (type) {
          case ALL_MY_TEAMS: {
            return `/team/${ALL_MY_TEAMS}/${url}`;
          }
          case "individual": {
            return `/team/${selectedTeams.team.id}/${url}`;
          }
          default: {
            const exhaustiveCheck: never = type;
            throw new Error(exhaustiveCheck);
          }
        }
      }
      default: {
        const exhaustiveCheck: never = scope;
        throw new Error(exhaustiveCheck);
      }
    }
  }

  return `/${url}`;
};

const apiRouteWithTeam = (props: Props) => {
  return `/api${pageRouteWithTeam(props)}`;
};

const updatePageUrlWithSelectedTeam = (existingUrl: string, selectedTeams: SelectedTeams) => {
  const matches = /\/team\/[\[\]a-zA-Z0-9-]+\/(.*)/g.exec(existingUrl);
  if (matches?.at(1)) {
    return `/team/${selectedTeams.type === ALL_MY_TEAMS ? ALL_MY_TEAMS : selectedTeams.team.id}/${matches.at(1)}`;
  }
  return `/team/${selectedTeams.type === ALL_MY_TEAMS ? ALL_MY_TEAMS : selectedTeams.team.id}${existingUrl}`;
};

export const Routes = {
  API,
  PAGE,
  Utils: {
    apiRouteWithTeam,
    pageRouteWithTeam,
    updatePageUrlWithSelectedTeam,
  },
};
