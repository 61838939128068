import React, { useState, ReactNode } from "react";

export interface INotificationContext {
  readonly notificationText: string;
  readonly notificationBullets?: string[];
  readonly notificationIsShown: boolean;
  readonly notificationAutoDismisses?: boolean;
  readonly notificationType: "success" | "error" | "warning";
  readonly showSuccessNotification: (
    arg: string,
    bullets?: string[],
    shouldAutoDismiss?: boolean
  ) => void;
  readonly showErrorNotification: (
    arg: string,
    bullets?: string[],
    shouldAutoDismiss?: boolean
  ) => void;
  readonly showWarningNotification: (
    arg: string,
    bullets?: string[],
    shouldAutoDismiss?: boolean
  ) => void;
  readonly hideNotification: () => void;
}

export const NotificationContext = React.createContext({} as INotificationContext);

const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [notificationText, setNotificationText] = useState<string>("");
  const [notificationBullets, setNotificationBullets] = useState<string[]>();
  const [notificationIsShown, setNotificationIsShown] = useState<boolean>(false);
  const [notificationAutoDismisses, setNotificationAutoDismisses] = useState<boolean>();

  const [notificationType, setNotificationType] = useState<"success" | "error" | "warning">(
    "success"
  );

  const showNotification = (
    text: string,
    type: "success" | "error" | "warning",
    bullets?: string[],
    shouldAutoDismiss?: boolean
  ) => {
    setNotificationText(text);
    setNotificationBullets(bullets);
    setNotificationType(type);
    setNotificationIsShown(true);
    setNotificationAutoDismisses(shouldAutoDismiss);
  };

  const showSuccessNotification = (
    text: string,
    bullets?: string[],
    shouldAutoDismiss: boolean = true
  ) => showNotification(text, "success", bullets, shouldAutoDismiss);
  const showErrorNotification = (text: string, bullets?: string[], shouldAutoDismiss?: boolean) =>
    showNotification(text, "error", bullets, shouldAutoDismiss);
  const showWarningNotification = (text: string, bullets?: string[], shouldAutoDismiss?: boolean) =>
    showNotification(text, "warning", bullets, shouldAutoDismiss);

  const hideNotification = () => setNotificationIsShown(false);

  const contextValue: INotificationContext = {
    notificationText,
    notificationBullets,
    notificationIsShown,
    notificationType,
    notificationAutoDismisses,
    showSuccessNotification,
    showErrorNotification,
    showWarningNotification,
    hideNotification,
  };

  return (
    <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>
  );
};

export default NotificationProvider;
