import { SelectedTeams } from "feature/teams/models";

export type NavigationIcons =
  | "GlobeAltIcon"
  | "ArrowsRightLeftIcon"
  | "PencilSquareIcon"
  | "BuildingStorefrontIcon"
  | "Cog8ToothIcon"
  | "HomeIcon"
  | "ChatBubbleLeftRightIcon"
  | "TableCellsIcon"
  | "UsersIcon"
  | "LightBulbIcon"
  | "QuestionMark"
  | "PresentationChartBarIcon"
  | "UserCircleIcon"
  | "ClipboardDocumentCheckIcon"
  | "WrenchScrewdriverIcon"
  | "Dollar"
  | "AdjustmentsHorizontalIcon";

export type NavigationRoutes =
  | "/advisorhq"
  | "/accounts"
  | "/clients"
  | "/models"
  | "/discover"
  | "/proposals"
  | "/service"
  | "/playbooks"
  | "/admin"
  | "/billing"
  | "/admin/accounts-attention"
  | "/client-dashboard-tools";

interface CoreNavigationItem {
  readonly name: string;
  readonly enabled: boolean;
}

interface InternalNavigationItem extends CoreNavigationItem {
  readonly href: NavigationRoutes;
  readonly isExternalLink?: never;
}

interface ExternalNavigationItem extends CoreNavigationItem {
  readonly href: string;
  readonly isExternalLink: true;
}

export type NavigationItem = InternalNavigationItem | ExternalNavigationItem;

// Not yet implemented, just here as an example
interface UserSlideOverProps {
  readonly type: "user";
  readonly userId: string;
}

interface OrganizationSlideOverProps {
  readonly type: "organization";
  readonly organizationId: string;
  readonly hasEditPermissions: boolean;
}

interface RepresentativeSlideOverProps {
  readonly type: "representative";
  readonly hasEditPermissions: boolean;
  readonly employeeId: string;
}

export type SlideOverProps =
  | OrganizationSlideOverProps
  | UserSlideOverProps
  | RepresentativeSlideOverProps;

export interface UserNavigationItem {
  readonly name: string;
  readonly href?: string;
  // If Slide over props are present, we will use them to load a custom navigation slideover experience
  readonly slideOverProps?: SlideOverProps;
}

export type Entitlement =
  | "ClientOnboarding"
  | "Discover"
  | "Investment"
  | "ProposalGeneration"
  | "Integrations"
  | "InternalAdvisor"
  | "CompoundAccess";

// This enum should primarily be used server-side when evaluating the FF. The value is the key to the feature in Split.io.
export enum FeatureFlags {
  ClientOnboarding = "client-onboarding",
  ShowOwnProspects = "show_own_prospects",
  ShowProspects = "show_prospects",
  ShowIPSTab = "show_ips_tab",
  AccessControl = "teams-access-control",
  SimplifiedOnboarding = "simplify-onboarding-flow",
  ClientContext = "client-context",
  LeadManagement = "lead-management",
  ShowMeetingNotesEmailResponseCard = "show_meeting_notes_email_response_card",
}
export type FeatureFlag = (keyof typeof FeatureFlags)[number];

export enum Kind {
  // Add a "Client" type when a use case comes up for launching to clients
  Employee = "employee", // Use this when the user is an Employee
  User = "user", // Use this for "Anonymous Users" hitting our app
}

// This should contain a list of any FeatureFlag values that need to be evaluated directly by frontend components.
// It might not always be desirable to evaluate them here, because they will be evaluated for all pages.
// The approach of evaluating a feature flag and passing it via props can still be desirable.
export const flagsToEvaluateForAllPages: FeatureFlags[] = [
  /* Add flags here */
  FeatureFlags.AccessControl,
  FeatureFlags.ClientContext,
] as const;

type ClientSideAvailableFeatureFlags = FeatureFlags.AccessControl | FeatureFlags.ClientContext;
export type FeatureFlagToTreatment = {
  // We will likely only use `boolean` | `undefined`, but other types of experiments could be executed, so string/number could be leveraged. It could also be extended to more complex values as needed.
  readonly [key in ClientSideAvailableFeatureFlags]: string | boolean | number | undefined;
};

/*
  This data will be fed into a React.Context. We should keep this pretty limited. This is really for global context. Things that could make sense in here:
  * Access: Permissions, Entitlements, Feature Flags, etc.
  * Localization info: Currency, language, region, etc.

  This information essentially needs to be available through all parts of the app. It might be part of the `<Layout>`
*/
export interface AppContext {
  readonly entitlements: Entitlement[]; // Logged in user's firm's entitlements
  readonly featureFlags: FeatureFlagToTreatment; // Logged in user's feature flag + treatment evaluation result
  readonly permissions: string[]; // Logged in user permissions
  readonly selectedTeams: SelectedTeams;
}

/* It might feel a little weird to have some of the fields in here be included in `NavigationElements`.
This has evolved to be the shared place to do server-side rendering things that is passed into every page.

In some future iteration of this feature, it would be desirable to have this be rebranded to something similar to: `AppContext`.

It is essentially things that are server-side rendered on almost every page. It should only contain information that is fundamental to
all/most pages/features in the app.
*/
// TODO - Rename to GlobalAppState/FrontendAppContext/etc
export interface NavigationElements {
  readonly navigationItems: NavigationItem[];
  readonly userNavigationItems: UserNavigationItem[];
  readonly sideBarOpen: boolean;
  readonly employeeId: string;
  readonly appContext: AppContext;
}
