import { ReactNode, createContext, useContext } from "react";
import { AppContext as IAppContext, FeatureFlags } from "feature/navigation/models";

// get access to the context
export const useAppContext = () => {
  const { entitlements, featureFlags, permissions, selectedTeams } = useContext(AppContext);
  return {
    entitlements,
    featureFlags,
    permissions,
    selectedTeams,
  };
};

export const defaultAppContext: IAppContext = {
  entitlements: [],
  featureFlags: {
    [FeatureFlags.AccessControl]: undefined,
    [FeatureFlags.ClientContext]: undefined,
  },
  permissions: [],
  selectedTeams: {
    type: "all",
  },
};

const AppContext = createContext(defaultAppContext);

interface Props {
  readonly appContext: IAppContext;
  readonly children: ReactNode;
}

export const AppContextProvider = (props: Props) => {
  return <AppContext.Provider value={props.appContext}>{props.children}</AppContext.Provider>;
};
