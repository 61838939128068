import { datadogRum } from "@datadog/browser-rum";
import React, { useEffect, useState } from "react";
import { useCurrentUserQuery } from "feature/user/queries";
import {
  APP_ENV_LOCAL,
  getClientSafeAppStage,
  getClientSafeDatadogEnv,
} from "shared/environmentUtils";
import { ampli } from "src/ampli";

export const Monitoring = () => {
  const { data: currentUserData } = useCurrentUserQuery();
  const stage = getClientSafeAppStage();
  const [datadogInitCalled, setDatadogInitCalled] = useState<boolean>(false);

  useEffect(() => {
    if (stage !== APP_ENV_LOCAL) {
      if (!datadogInitCalled) {
        datadogRum.init({
          applicationId: "b772efec-c0d9-4eeb-ac3c-e075911c2df0",
          clientToken: "pubbb78c5e0a024155ce236f38e57f5f414",
          site: "datadoghq.com",
          service: "contro-app",
          // Use `production` to match the `compound` datadog application
          env: getClientSafeDatadogEnv(),
          version: "1.0.0",
          allowedTracingUrls: [
            /https:\/\/.*\.compoundplanning\.com/,
            /https:\/\/.*\.alternativ\.com/,
          ],
          defaultPrivacyLevel: "mask-user-input",
          sessionReplaySampleRate: 100,
          sessionSampleRate: 100,
          trackLongTasks: true,
          trackResources: true,
          trackUserInteractions: true,
        });
        setDatadogInitCalled(true);
      }

      if (currentUserData !== undefined) {
        datadogRum.setUser({
          id: currentUserData.user.id,
          name: currentUserData.user.name,
        });
      }
    }
  }, [currentUserData, stage, datadogInitCalled]);

  useEffect(() => {
    if (!ampli.isLoaded) {
      ampli.load({ environment: "gabetest" });
    }

    if (currentUserData !== undefined) {
      ampli.identify(currentUserData.user.id, {
        email: currentUserData.user.email ?? undefined,
        name: currentUserData.user.name,
        firmName: currentUserData.user.firmName,
      });
    }
  }, [currentUserData, stage]);

  return <></>;
};
