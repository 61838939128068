import { useContext } from "react";
import { NotificationContext } from "..";

function useNotification() {
  const {
    notificationText,
    notificationBullets,
    notificationIsShown,
    notificationType,
    notificationAutoDismisses,
    showSuccessNotification,
    showErrorNotification,
    showWarningNotification,
    hideNotification,
  } = useContext(NotificationContext);
  return {
    notificationText,
    notificationBullets,
    notificationIsShown,
    notificationType,
    notificationAutoDismisses,
    showSuccessNotification,
    showErrorNotification,
    showWarningNotification,
    hideNotification,
  };
}

export default useNotification;
