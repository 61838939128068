import { CompoundClientType } from "@compoundfinance/compound-core";
import {
  UseQueryResult,
  useQuery,
  UseInfiniteQueryResult,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
  keepPreviousData,
  InfiniteData,
} from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import Error from "next/error";
import {
  Client,
  DashboardUserResponse,
  EmployeeResponse,
  GetEmployeesResponse,
  GetUserResponse,
  PaginatedClientsResponse,
  EmployeeTeamsResponse,
} from "./models";
import { EmployeeFilters, UpdateClient, UpdateEmployeePreference } from "./validation";
import { DefaultAPIParams } from "feature/filtersV2/models";
import { householdKeys } from "feature/household/queries";
import { prospectKeys } from "feature/prospects/queries";
import { Routes } from "feature/routes";
import { useAppContext } from "providers/AppContextProvider";
import useNotification from "providers/NotificationProvider/useNotification";

export const CLIENTS_DEFAULT_PAGE_SIZE = 100;
export const AUTHENTICATION_TOKEN_KEY = "compound_auth";

const dashboardLoginDomain = process.env.NEXT_PUBLIC_COMPOUND_API_URL ?? "missing value";

export const DEFAULT_QUERY_KEYS: DefaultAPIParams = {
  ascFields: [],
  descFields: [],
  includeDescendantOrganizations: true,
};

const formatListKey = (fields: string[] = []) => {
  if (fields.length === 0) {
    return "-";
  }
  return fields.join("-");
};

export const userKeys = {
  all: ["users"] as const,
  current: () => [...userKeys.all, "current"] as const,
  currentTeams: () => [...userKeys.current(), "teams"] as const,
  clients: () => [...userKeys.all, "clients"] as const,
  filtered: (filters: DefaultAPIParams) => {
    const { filter, ascFields, descFields, includeDescendantOrganizations } = filters;
    const ascFieldKey = formatListKey(ascFields);
    const descFieldKey = formatListKey(descFields);
    return [
      ...userKeys.clients(),
      {
        filter,
        ascFieldKey,
        descFieldKey,
        includeDescendantOrganizations,
      },
    ] as const;
  },
  client: (clientId: string) => [...userKeys.clients(), clientId] as const,
};

export const dashboardUserKeys = {
  current: () => ["current"] as const,
};

export const employeeKeys = {
  all: ["employees"] as const,
  filtered: (filters: EmployeeFilters) => [...employeeKeys.all, "filtered", filters] as const,
  id: (employeeId: string) => [...employeeKeys.all, employeeId] as const,
};

type AuthResult = "retry" | "fail";

async function dashboardLogin(): Promise<DashboardUserResponse | AuthResult> {
  return await axios
    .get(`${dashboardLoginDomain}/api/user`, {
      withCredentials: true,
    })
    .then((res): DashboardUserResponse | AuthResult => {
      if (typeof res.data.user === "object") {
        return res.data.user as DashboardUserResponse;
      }

      return "fail";
    })
    .catch(async (err): Promise<DashboardUserResponse | AuthResult> => {
      if (!(err instanceof AxiosError) || err.response === undefined) {
        // Bail if we get an unexpected error. It's probably something CORs related.
        return "fail";
      }

      if (err.response.status === 403 || err.response.status === 401) {
        const token = await axios.get<string>("/api/auth/signed-token");
        if (typeof token.data !== "string") {
          // Something real bad happened..
          return "fail";
        }

        return await axios
          .post(
            `${dashboardLoginDomain}/api/user/authenticate/sso/advisor_redirect`,
            {
              token: token.data,
            },
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            if (typeof res.data.user === "object") {
              if (typeof window !== "undefined") {
                const { localStorage } = window;
                const compoundAuthToken = res.data.token;
                localStorage.setItem(
                  AUTHENTICATION_TOKEN_KEY,
                  JSON.stringify({
                    token: compoundAuthToken,
                    method: "advisor_redirect",
                  })
                );
              }
              return res.data.user as DashboardUserResponse;
            }

            return "fail";
          })
          .catch((): AuthResult => {
            // Just fail at this point. We can wire up retries once we see how this can fail.
            return "fail";
          });
      } else if (err.response.status === 500) {
        return "retry";
      }

      return "fail";
    });
}
export const useDashboardUserQuery = (
  hasDashboardAccess: boolean
): UseQueryResult<DashboardUserResponse | null, Error> =>
  useQuery({
    queryKey: dashboardUserKeys.current(),
    queryFn: async () => {
      const result: DashboardUserResponse | AuthResult = await dashboardLogin();

      if (typeof result === "object") {
        return result;
      } else if (result === "retry") {
        const retry = await dashboardLogin();
        if (typeof retry === "object") {
          return retry;
        }
      }

      return null;
    },
    staleTime: Infinity,
    refetchInterval: 300_000, // 5 minutes
    refetchIntervalInBackground: true,
    enabled: hasDashboardAccess,
  });

export const useCurrentUserQuery = (): UseQueryResult<GetUserResponse, Error> =>
  useQuery({
    queryKey: userKeys.current(),
    queryFn: async () => {
      const response: AxiosResponse<GetUserResponse> = await axios.request({
        method: "GET",
        url: "/api/user/current",
      });
      return response.data;
    },
    // Logged in user info doesn't change frequently. If user updates their profile, the user cache should be cleared
    staleTime: Infinity,
  });

export const useCurrentUserTeamsQuery = (): UseQueryResult<EmployeeTeamsResponse, Error> => {
  const { featureFlags } = useAppContext();
  const routeConfig = Routes.API.User.Teams;

  return useQuery({
    queryKey: userKeys.currentTeams(),
    queryFn: async () => {
      const response: AxiosResponse<EmployeeTeamsResponse> = await axios.request({
        method: routeConfig.method,
        url: Routes.Utils.apiRouteWithTeam({
          url: routeConfig.getUrl(),
          scope: routeConfig.scope,
          featureFlagEnabled: featureFlags["teams-access-control"] === true,
          selectedTeams: {
            type: "all", // Override this so it's set to all so the TeamSelector works. Other workflows should prefer the value from the AppContext
          },
        }),
      });
      return response.data;
    },
  });
};

const getPagedClients = async (
  url: string,
  pageParam: string | undefined,
  filters: DefaultAPIParams
): Promise<PaginatedClientsResponse> => {
  const resp = await axios.request<PaginatedClientsResponse>({
    method: Routes.API.Client.List.method,
    url,
    params: {
      cursor: pageParam,
      ...filters,
    },
    // This is necessary to serialize an array in a request query properly with axios.
    // See: https://github.com/axios/axios/issues/5058
    paramsSerializer: {
      indexes: null,
    },
  });
  return resp.data;
};

export const useClientsQuery = (
  filters: DefaultAPIParams
): UseInfiniteQueryResult<InfiniteData<PaginatedClientsResponse>> => {
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.Client.List;

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(),
    scope: routeConfig.scope,
    selectedTeams,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
  });

  return useInfiniteQuery({
    queryKey: userKeys.filtered(filters),
    queryFn: ({ pageParam }: { pageParam?: string }) => getPagedClients(url, pageParam, filters),
    getNextPageParam: (lastPage) => lastPage.cursor,
    staleTime: Infinity,
    retry: 1,
    placeholderData: keepPreviousData,
    initialPageParam: undefined,
  });
};

export const useClientQuery = (
  householdId: string,
  clientId: string,
  enabled: boolean
): UseQueryResult<{ user: Client }, Error> => {
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.Client.Id;

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(householdId, clientId),
    scope: routeConfig.scope,
    selectedTeams,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
  });

  return useQuery({
    queryKey: userKeys.client(clientId),
    queryFn: async () => {
      const response: AxiosResponse<{ user: Client }> = await axios.request({
        method: routeConfig.method,
        url,
      });
      return response.data;
    },
    enabled,
    retry: 1,
  });
};

const retrieveAdminEmployees = async (
  url: string,
  pageParam: string | undefined,
  filters?: EmployeeFilters
): Promise<GetEmployeesResponse> => {
  const response = await axios.request({
    method: Routes.API.Admin.EmployeeList.method,
    url,
    params: { cursor: pageParam, ...filters },
  });
  return response.data;
};
interface AdminEmployeeQueryProps {
  readonly disabled?: boolean;
  readonly filters?: EmployeeFilters;
}

export const useAdminEmployeesQuery = (
  data: AdminEmployeeQueryProps
): UseInfiniteQueryResult<InfiniteData<GetEmployeesResponse>> => {
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.Admin.EmployeeList;

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(),
    scope: routeConfig.scope,
    selectedTeams,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
  });

  return useInfiniteQuery({
    queryKey: data.filters ? employeeKeys.filtered(data.filters) : employeeKeys.all,
    queryFn: ({ pageParam }: { pageParam?: string }) =>
      retrieveAdminEmployees(url, pageParam, data.filters),
    getNextPageParam: (lastPage) => lastPage.cursor,
    staleTime: 1000 * 60 * 60 * 12, // 12 hour
    enabled: !data.disabled,
    retry: 1,
    placeholderData: keepPreviousData,
    initialPageParam: undefined,
  });
};

export const useEmployeeQuery = (
  employeeId: string,
  enabled: boolean
): UseQueryResult<EmployeeResponse, Error> => {
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.Employee.Id;

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(employeeId),
    scope: routeConfig.scope,
    selectedTeams,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
  });

  return useQuery({
    queryKey: employeeKeys.id(employeeId),
    queryFn: async () => {
      const response: AxiosResponse<EmployeeResponse> = await axios.request({
        method: routeConfig.method,
        url,
      });
      return response.data;
    },
    staleTime: Infinity,
    enabled,
    retry: 1,
    placeholderData: keepPreviousData,
  });
};

export const useClientPatchMutation = (householdId: string, clientId: string) => {
  const queryClient = useQueryClient();
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.Client.Update;

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(householdId, clientId),
    scope: routeConfig.scope,
    selectedTeams,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
  });

  return useMutation({
    mutationFn: (formData: UpdateClient) =>
      axios.request<UpdateClient, AxiosResponse<{ user: Client }>>({
        url,
        method: routeConfig.method,
        data: formData,
      }),

    onSuccess: async (response) => {
      if (response.status === 200) {
        // If successfully saved, clear our the clients cache since there's a new one.
        await queryClient.invalidateQueries({
          queryKey: userKeys.client(clientId),
        });
        await queryClient.invalidateQueries({
          queryKey: userKeys.clients(),
        });
      }
    },
  });
};

export const useClientDeleteMutation = (
  householdId: string,
  clientId: string,
  type: CompoundClientType
) => {
  const queryClient = useQueryClient();
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.Client.Delete;

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(householdId, clientId),
    scope: routeConfig.scope,
    selectedTeams,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
  });

  return useMutation({
    mutationFn: () =>
      axios.request({
        url,
        method: routeConfig.method,
      }),

    onSuccess: async (response) => {
      if (response.status === 200) {
        await queryClient.invalidateQueries({
          queryKey: type === "Prospect" ? prospectKeys.all : userKeys.clients(),
        });
      }
    },
  });
};

type EmployeeUpdateInput = {
  readonly employeeId: string;
  readonly modifiedKey: string;
  readonly modifiedValue?: string | null;
};

export const useEditEmployeeQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData: EmployeeUpdateInput) =>
      axios.request({
        method: "PATCH",
        url: `/api/employee/${formData.employeeId}`,
        data: {
          [formData.modifiedKey]: formData.modifiedValue === "" ? null : formData.modifiedValue,
        },
      }),

    onSuccess: async (response: AxiosResponse, variables: EmployeeUpdateInput) => {
      await queryClient.invalidateQueries({
        queryKey: employeeKeys.id(variables.employeeId),
      });
      await queryClient.invalidateQueries({
        queryKey: userKeys.current(),
      });
    },
  });
};

export const useUpdateEmployeePreferences = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData: UpdateEmployeePreference) =>
      axios.request({
        method: "PATCH",
        url: `/api/employee/${formData.employeeId}/preference`,
        data: formData,
      }),
    onSuccess: async (response: AxiosResponse, variables: UpdateEmployeePreference) => {
      await queryClient.invalidateQueries({
        queryKey: userKeys.current(),
      });
      await queryClient.invalidateQueries({
        queryKey: employeeKeys.id(variables.employeeId),
      });
      if (variables.selectedTeam !== undefined) {
        await queryClient.invalidateQueries({ queryKey: householdKeys.all });
      }
    },
  });
};

export const useCreateCompoundDashboard = (accountGroupId: string) => {
  const queryClient = useQueryClient();
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.AccountGroup.CreateDashboard;

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(accountGroupId),
    scope: routeConfig.scope,
    selectedTeams,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
  });

  const { showSuccessNotification, showErrorNotification } = useNotification();
  return useMutation({
    mutationFn: () =>
      axios.request({
        url,
        method: routeConfig.method,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: householdKeys.id(accountGroupId),
      });
      showSuccessNotification("Dashboard created");
    },
    onError: () => {
      showErrorNotification("Error creating Dashboard", undefined, true);
    },
  });
};
